import React from 'react';
import { Link, graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from "../components/seo"
import { Fade } from 'react-awesome-reveal';
const blogTemplate = ({
	data: {allWordpressPost},
	pageContext: {
		catId,
		catName,
		catSlug,
		categories,
		humanPageNumber,
		numberOfPages,
	},
	}) => 
	<Layout>
	<SEO description="Inspiration, thoughts and reports from the webplace team" title="Blog" />
	
	<nav aria-label="breadcrumb" className="breadcrumbs-section border-top border-bottom mb-0">
		<div className="container">
			<ol className="breadcrumb mb-0 py-2 px-0">
				<li className="breadcrumb-item"><a href="/">Home</a></li>
				<li className="breadcrumb-item active">{catName}</li>
			</ol>
		</div>
	</nav>
	<section className="intro-section pt-10 pb-5 pt-lg-26 pb-lg-14">
		<div className="container">
			<div className="row align-items-center justify-content-between">
				<div className="col-12 col-md-7 mb-8 mb-md-0">
						<h1 className="mb-0">Inspiration, thoughts and reports from the Webplace team</h1>
					</div>
					<div className="col-12 col-md-5 text-center text-md-right d-print-none">
					<div className="img-holder"> 
						<img src={'/intro-5.svg'} alt="" width="426" height="242" />
					</div>
				</div>
			</div>
		</div>
	</section>
   <div className="container">
      <div className="works-section py-6 py-lg-10 border-top">
         
         <div className="load-more-holder mb-8 mb-lg-16 viewport-section in-viewport">
            <div className=" position-relative row target-holder mb-8 mb-lg-14 fadeInUp">
			
			{allWordpressPost.edges.map((post,key) => (
		
		
				<Fade triggerOnce="true" direction="top" className="col-12 col-md-6 col-lg-4 position-relative mb-5 mb-md-0 pb-md-12" key={key} >
					<div className="card">
						<Link className="card-img-top d-block position-relative" to={`/blog/${post.node.slug}`}>
						<strong className="sr-only">This is a card image link</strong> 
						<img src={`${post.node.acf.thumbnail_image.localFile.childImageSharp.fluid.srcWebp}`} alt="" width="" height="" /> 
						</Link>
						<div className="card-body">
							
							<h2 className="card-title fw-700 text-blue"><Link to={`/blog/${post.node.slug}`}><span dangerouslySetInnerHTML={{ __html: post.node.title}} /></Link></h2>
							<p className="card-text" dangerouslySetInnerHTML={{ __html: post.node.acf.intro_text }}/>
							
						</div>
					</div>
				</Fade>
				
				))}
				</div>
			</div>
		</div>
	</div>
		
	</Layout>	
	; 
	export default blogTemplate;

	export const pageQuery = graphql`
		query ($catSlug: String!, $skip: Int!, $limit: Int!) {
			allWordpressPost(
				filter: { categories: { elemMatch: {slug: { eq: $catSlug } } } }
				skip: $skip
				limit: $limit
				sort: {fields: date, order: DESC}
			) {
				edges {
					node {
						id
						slug
						status
						template
						format
						title
						content
						date(formatString: "DD, MMM, YYYY")
						acf {
							intro_text
							thumbnail_image {
								localFile {
									publicURL
									childImageSharp {
										fluid {
											srcWebp
											srcSetWebp
											originalImg
											originalName
										}
									}
								}
							}
		
						}
						
					}
					
				}
		}
	}
	`